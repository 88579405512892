import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Router, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import './index.css'
import registerServiceWorker from './registerServiceWorker'

import App from './App'
import Login from './pages/Login'
import Search from './pages/Search'
import Details from './pages/Details'
import Read from './pages/Read'
const userID = localStorage.getItem('userID')
window.userID = userID!
window.userID = '10001'

const history = createBrowserHistory()
//路由控制
const router = (
  <Router history={history}>
    <Switch>
      <Route path="/" exact={true} component={App} />
      <Route path="/Login" component={Login} />
      <Route path="/Search" component={Search} />
      <Route path="/Details" component={Details} />
      <Route path="/Read" component={Read} />
    </Switch>
  </Router>
)
if (isNaN(parseInt(window.userID))) {
  history.replace(
    '/Login?rd=' + window.location.pathname + window.location.search
  )
}
ReactDOM.render(router, document.getElementById('root') as HTMLElement, () => {
  if (isNaN(parseInt(window.userID))) {
    history.replace('/Login')
  }
  const node = document.getElementById('loading-m')
  if (node !== null) {
    node.style.display = 'none'
  }
})
registerServiceWorker()
