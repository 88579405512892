import React, { Fragment } from 'react'
import { BaseComponent, InputComponent as Input } from '../components/index'
import { Login as LoginFunc } from '../common/ApiHelper'

class Login extends BaseComponent {
  public charWidthRegister = '4em'
  public charWidthLogin = '3em'
  state = {
    TabIndex: 0,
    account: '',
    password: '',
    countname: '',
  }
  TabOnClick = (Index: number) => {
    this.setState({ TabIndex: Index })
  }
  public OnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget
    const _state = this.state
    let state: Partial<typeof _state> = {}
    switch (name) {
      case 'countRegister':
      case 'countLogin':
        state = { account: value }
        break
      case 'pwdRegister':
      case 'pwdLogin':
        state = { password: value }
        break
      case 'nameRegister':
        state = { countname: value }
    }
    this.setState(state)
  }
  /**
   * 登录
   */
  public OnClickLogin = () => {
    const { account, password } = this.state
    this.ShowToast('登录中....', 0)
    LoginFunc(account, password, (res) => {
      this.RemoveToast()
      if (res.code === 0) {
        this.ShowToast(res.emsg, 1, 2000)
        return
      }
      localStorage.setItem('userID', res.Date.usercode)
      window.userID = res.Date.usercode
      let rd = this.GetQueryString(this.props.location.search, 'rd')
      rd = rd === null ? '/' : rd
      this.props.history.push(rd)
    })
    //鹿邑图书馆登录
    // var socketUrl = 'ws://117.158.82.50:6088'
    // var socket: WebSocket
    // var socketRead = false
    // socket = new WebSocket(socketUrl)
    // socket.onopen = function (res) {
    //   console.log(res)
    //   console.log('成功连接到服务器...')
    //   socketRead = true
    //   socket.send('9300CNlyxtsg|CO123456')
    //   socket.send(
    //     '6300120220809    0945450123456789AOdp2Library|AA' +
    //       account +
    //       '|AD' +
    //       password +
    //       ''
    //   )
    // }
    // socket.onclose = (e) => {
    //   console.log('与服务器连接关闭: ' + e.code)
    //   this.RemoveToast()
    //   socketRead = false
    // }
    // socket.onmessage = (res) => {
    //   var result = res.data
    //   if (
    //     result.indexOf('64             ') !== -1 &&
    //     result.indexOf('BLY') !== -1
    //   ) {
    //     this.RemoveToast()
    //     localStorage.setItem('userID', account)
    //     window.userID = account
    //     let rd = this.GetQueryString(this.props.location.search, 'rd')
    //     rd = rd === null ? '/' : rd
    //     this.props.history.push(rd)
    //   }
    // }
  }
  /**
    rendername
     */
  public render() {
    const { charWidthLogin, OnChange, OnClickLogin } = this
    const { TabIndex, account, password, countname } = this.state
    let BodyContent
    switch (TabIndex) {
      case 0: //登录
        BodyContent = (
          <Fragment>
            <Input
              name="countLogin"
              key="countLogin"
              title="账号"
              placeholder="请输入账号"
              width={charWidthLogin}
              type="text"
              value={account}
              maxLength={11}
              onChange={OnChange}
            />
            <Input
              name="pwdLogin"
              key="pwdLogin"
              title="密码"
              type="password"
              placeholder="请输入密码"
              width={charWidthLogin}
              value={password}
              maxLength={20}
              onChange={OnChange}
            />
            <span className="btn" onClick={OnClickLogin}>
              登 录
            </span>
          </Fragment>
        )
        break
    }
    return (
      <div className="c-box">
        <div className="c-banner1">
          <img src={require('../img/banner1.jpg')} />
        </div>
        <div className="c-index-menu">
          <div className="login">
            <div className="head"></div>
            <div className="body">{BodyContent}</div>
          </div>
        </div>
      </div>
    )
  }
}
export default Login
